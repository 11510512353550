
main{
    position:relative;
    align-content: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.vidContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rightText{
    padding-left: 0px;
    padding-top: 10px;
}

@media screen and (min-width: 720px) {

    .vidContainer{
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .rightText{
        padding-left: 20px;
    }
}