.project{
    display: flex;
    justify-content: left;
    align-items: center;
    color:black;
    background-color: slategray;
    margin: 50px;
    width:80%;
    transform: translate(15%);
}
.project:hover{
    background-color: darkgray;
    width: 90%;
    transform: translate(2.5%);
    margin: 50px;
    cursor: pointer;
}
.blog{
    display: flex;
    justify-content: left;
    align-items: center;
    color: black;
    
    background-color: darkgray;
    margin: 50px;
    width: 90%;
    transform: translate(2.5%);
    
}

.projectDescription{
    padding:1em;
    width: 100%;
}
.image{
    width: 100px;
    height: 100px;
    padding: 10px;
    object-fit: contain;
}
.blog p{
    cursor: default;
}


