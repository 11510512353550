#projects ul{
    list-style-type: none;
    margin-bottom: 100px;
}


@media screen and (min-width: 720px) {
    #projects{
        justify-content: center;
        width: 100%;
    }
}