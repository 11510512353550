body{
    position:flex;
    background-color: rgb(18, 9, 9);
    font-family: monospace, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

main{
    position:relative;
    padding-top: 150px;
    padding-bottom: 50px;

    align-content: center;
}

header{
    position: fixed;
    flex-direction: column;
    z-index: 9;
    background-color: whitesmoke;
    width: 100%;
    display: flex;
    top:0;
    left: 0;
    align-items: center;
    justify-content: space-around;
}
.displayedHeader{
    display: flex;
    max-height: 18px;
}
.navLink{
    display: flex;
    height: 100%;
    transition: 0.3s;
}
.navLink:hover{
    background-color: rgb(18, 9, 9);
    color:white;
}
footer{
    position: fixed;
    flex-direction: column;
    z-index: 9;
    opacity: 0.9;
    background-color: whitesmoke;
    width: 100%;
    display: flex;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: space-around;
}
nav a{
    color: black;
}
a{
    text-decoration: none;
}
header nav{
    display: flex;
    height: 40px;
}
header nav ul{ 
    display: flex;
    width: 100%;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;

}
header nav ul li {
    padding: 10px;
    text-align: center;
    display: inline;
}
footer ul{
    display: flex;
    width: 100%;
    padding-left: 0px;
    justify-content: space-around;
    align-items: center;
}
footer ul li {
    padding: 10px;
    text-align: center;
    display: inline;
}
.red {
    border: 2px solid red;
    border-radius: 4px;
}
/* PROJECT LIST */
#projects ul{
    list-style-type: none;
}
/* Project list item, child of an a link to deployed project. */
.project{
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    margin: 50px;
    transition: 0.3s;
}
.project:hover{
    background-color: slategray;
    transform: scale(1.1);
}
.description{
    padding-left: 10px;
    padding-right: 10px;
}
.project img{
    max-width: 100px;
    max-height: 100px;
    padding: 10px;
}




@media screen and (min-width: 720px) {
    /* Default formatting is mobile layout, 
    below code swaps from row to column when the page is large enough */
    header{
        flex-direction: row;
        align-items: baseline;
        justify-content: space-evenly;
    }
    /* Moves content up as nav bar shrinks. */
    main{
        padding-top: 100px;

        padding-bottom: 100px;

    }
    header nav{
        display: flex;
        height: 50px;
    }
  
}